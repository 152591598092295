import styled from "styled-components";

export const Center = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 0 2em;
  margin: 0 auto;
  box-sizing: border-box;
`

export const BigCenter = styled(Center)`
  max-width: 1300px;
`

export const PageTitle = styled.h1`
  font-size: 4em;
  margin-top: 2.3em;
  margin-bottom: 1.2em;
  text-align: center;
  
  @media (max-width: 800px) {
    font-size: 3em;
  }
`
