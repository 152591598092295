import React from "react"
import styled from "styled-components"
import Layout from "../Layout";
import {Center, PageTitle} from "../shared/mix";
import ReactGA from "react-ga";

interface Props {

}

const Contact = (props: Props) => {
    const handleClick = (e: React.MouseEvent) => {
        ReactGA.event({
            category: 'Contact-Click',
            //@ts-ignore
            action: e.target.href
        });
    }

    return (
        <Layout>
            <Center>
                <PageTitle>Kontakt</PageTitle>
                <ItemList>
                    <Item>
                        <Name>Email & telefon</Name>
                        <SubItem>
                            <Icon><i className="fa-solid fa-phone"></i></Icon>
                            <a href="tel:703992854" target="_blank" rel="noreferrer" onClick={handleClick}>(+420) 703 992 854</a>
                        </SubItem>
                        <SubItem>
                            <Icon><i className="fa-solid fa-at"></i></Icon>
                            <a href="mailto:info@puhony.eu" target="_blank" rel="noreferrer" onClick={handleClick}>info@puhony.eu</a>
                        </SubItem>
                    </Item>
                    <Item>
                        <Name>Sociální sítě</Name>
                        <SubItem>
                            <Icon><i className="fa-brands fa-linkedin"></i></Icon>
                            <a href="https://www.linkedin.com/in/matej-puhony/" target="_blank" rel="noreferrer"
                               onClick={handleClick}>/in/matej-puhony/</a>
                        </SubItem>
                        <SubItem>
                            <Icon><i className="fa-brands fa-facebook-messenger"></i></Icon>
                            <a href="https://m.me/matej.puhony" target="_blank" rel="noreferrer" onClick={handleClick}>matej.puhony</a>
                        </SubItem>
                        <SubItem>
                            <Icon><i className="fa-brands fa-instagram"></i></Icon>
                            <a href="https://m.me/matej.puhony" target="_blank" rel="noreferrer" onClick={handleClick}>matej.puhony</a>
                        </SubItem>
                    </Item>
                    <Item>
                        <Name>Živnostenský rejstřík</Name>
                        <SubItem>
                            <Icon>IČO</Icon>
                            08265771
                        </SubItem>
                        <SubItem>
                            Fyzická osoba podnikající dle živnostenského zákona
                        </SubItem>
                        {/*
                        <SubItem>
                            <Icon>SÍDLO</Icon>
                            (viz. živnostenský rejstřík)
                        </SubItem>
                        <SubItem>
                            <Icon><i className="fa-solid fa-link"></i></Icon>
                            <a href="https://e-creditreform.cz/MATEJ-PUHONY/08265771?hl=cs" target="_blank">Živnostenský
                                rejstřík</a>
                        </SubItem>
                        */}
                    </Item>
                    <Item>
                        <Name>Platební údaje</Name>
                        <SubItem>
                            <Icon><i className="fa-solid fa-circle-info"></i></Icon>
                            Informace k platbě jsou vždy uvedené na faktuře.
                        </SubItem>
                    </Item>
                </ItemList>
            </Center>
        </Layout>
    )
}

export default Contact

const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.div`
margin: 2em;
max-width: 20em;
`

const Name = styled.h3`

`

const SubItem = styled.div`
  margin-bottom: 0.75em;

  a {
    color: inherit;
    text-decoration: initial;
  }
`

const Icon = styled.span`
  font-weight: bold;
  margin-right: 1em;
`
