import React from "react"
import styled from "styled-components"
import Layout from "../Layout";
import {Center, PageTitle} from "../shared/mix";
import {Paragraph, SectionTitle} from "../CV";
import CTA from "../CTA";

interface Props {

}

const Services = (props: Props) => {
    return (
        <Layout>
            <Center>
                <PageTitle>Služby</PageTitle>

                <Info>
                    <SectionTitle><Icon className="fa-solid fa-server"></Icon> Správa webu a hosting</SectionTitle>
                    <Paragraph>Máte webové stránky, které si nedokážete sami upravit, ale nový web zatím nepotřebujete?
                        někdy lze webu udělat nový design bez úpravy aktuálního redakčního systému.</Paragraph>


                    <SectionTitle><Icon className="fa-solid fa-mobile"></Icon> Mobilní aplikace</SectionTitle>
                    <Paragraph>Vývoj mobilní aplikace pomocí webových technologií. Vhodné jak pro ios tak i android -
                        díky sdílenému kódu mezi platformama.</Paragraph>


                    <SectionTitle><Icon className="fa-solid fa-window-maximize"></Icon> Webové stránky, aplikace,
                        systémy</SectionTitle>
                    <Paragraph>Řešení pro firemní prezentaci, pomoc při organizaci firemních procesů, nebo třeba
                        rezervační sytém.</Paragraph>


                    <SectionTitle><Icon className="fa-solid fa-compass-drafting"></Icon> Design</SectionTitle>
                    <Paragraph>Každý web či aplikace potřebuje napřed navrhnout jak má vypadat.</Paragraph>
                </Info>
            </Center>

            <CTA/>
        </Layout>
    )
}

export default Services

const Icon = styled.i`
  font-size: 3em;
  position: absolute;
  top: 0em;
  left: -1.5em;
  color: #80808078;
`

const Info = styled.div`
  padding-left: 6em;
`
