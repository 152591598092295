import React from "react"
import {Center} from "../shared/mix";
import styled from "styled-components";

interface Props {

}

const Logos = (props: Props) => {
    return (
        <LogosWrapper>
            <h3>Pracoval jsem již na těchto projektech</h3>
            <Images>
                <img src="/img/logos/lg.svg" alt="Laser Game HK"/>
                <img src="/img/logos/tchk.svg" alt="Technologické centrum HK" style={{maxHeight: "4em"}}/>
                <img src="/img/logos/zsbcuo.png" alt="ZŠ Bratří Čapků"/>
                <img src="/img/logos/logo_muuo_znak.png" alt="Město Ústí nad Orlicí"/>
                <img src="/img/logos/nextap.svg" alt="Nextap"/>
                <img src="/img/logos/paiwand.png" alt="Paiwand"/>
                <img src="/img/logos/steller.svg" alt="Steller" style={{height: "2.8em"}}/>
            </Images>
        </LogosWrapper>
    )
}

export default Logos

const LogosWrapper = styled.div`
  text-align: center;
  margin-bottom: 10em;
  
  h3 {
    opacity: 0.3;
    margin-bottom: 0;
  }
`

const Images = styled.div`
  img {
    max-height: 3em;
    margin: 1.5em 2em;
    filter: grayscale(100%);
    opacity: 0.7;
    vertical-align: middle;
  }
`
