import React, {useState} from "react"
import {Link} from "react-router-dom";
import styled from "styled-components";
import {Center} from "../shared/mix";

interface Props {
    children: React.ReactNode
}

const Layout = (props: Props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <Website>
            <Nav menuOpen={menuOpen}>
                <Center>
                    <Menu>
                        <Logo>Matěj Půhoný</Logo>
                        <Links menuOpen={menuOpen}>
                            <NavLink><Link to="/">Home</Link></NavLink>
                            <NavLink><Link to="/reference">Reference</Link></NavLink>
                            <NavLink><Link to="/kontakt">Kontakt</Link></NavLink>
                        </Links>
                        <Burger onClick={() => setMenuOpen(!menuOpen)}>
                            <i className="fa-solid fa-bars"></i>
                        </Burger>
                    </Menu>
                </Center>
            </Nav>
            <Main>
                {props.children}
            </Main>
            <Center>
                <Footer>
                    designed and coded by <i className="fa-solid fa-paint-roller"></i> <Name>Matěj Půhoný</Name> {new Date().getFullYear()}
                    {" | "}<a href="http://codeartists.eu/" target="_blank">#CodeArtists</a>
                </Footer>
            </Center>
        </Website>
    )
}

export default Layout

const Website = styled.div`
  box-sizing: border-box;
`

interface NavProps {
    menuOpen: boolean
}

const Nav = styled.nav<NavProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({menuOpen}) => menuOpen ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.8)"};
  backdrop-filter: ${({menuOpen}) => menuOpen ? "blur(0)" : "blur(0.3em)"};
  z-index: 1000;
  
  @media (min-width: 541px) {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(0.3em);
  }
`

const Main = styled.main`
   min-height: calc(100vh - 22em);
`

const Menu = styled.div`
  height: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.03em;
`

const Links = styled.div<NavProps>`
  display: flex;
  
  @media (max-width: 540px) {
    position: fixed;
    top: 5em;
    left: 0;
    background-color: white;
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #ececec;
    width: 100%;
    padding: 2em;
    box-shadow: 0 9px 11px 0 #f1f1f1b5;
    
    display: ${({menuOpen}) => menuOpen ? "flex" : "none"};
  } 
`

const NavLink = styled.div`
  padding: 0 1em;
  
  a {
    color: inherit;
    text-decoration: none;
    
    opacity: 0.6;
    
    &:hover {
      opacity: 0.9;
    }
  }
`

const Footer = styled.footer`
  text-align: right;
  border-top: 1px solid #eeeeee;
  padding: 1.5em 0;
  margin-top: 8em;
  color: #8c8c8c;
  
  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      opacity: 0.8;
    }
  }
`

const Name = styled.span`
  font-weight: bold;
`

const Burger = styled.div`
  font-size: 1.3em;
  transition: 0.15s;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.05);
  }
  
  @media (min-width: 541px) {
    display: none;
  }
`
