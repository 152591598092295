import React from "react"
import styled from "styled-components"
import Layout from "../Layout";
import {Center, PageTitle} from "../shared/mix";

interface Props {

}

const CV = (props: Props) => {
    return (
        <Layout>
            <PageTitle></PageTitle>
            <Center>
                <Cols>
                    <BgImage></BgImage>
                    <Info>
                        <Name>Matěj Půhoný</Name>
                        <Sub>Webdeveloper</Sub>

                        <SectionTitle>🖥️ Technologie & skills</SectionTitle>
                        <Paragraph>
                            JavaScript, Node.js, React.js, Redux, Styled components, Sass & Less, PHP, Laravel, Git,
                            Java, C#<br/>
                            Adobe Illustrator, Photoshop <br/>
                            Konfigurace DNS serverů, nastavování síťových prvků, správa počítačových sítí.
                        </Paragraph>

                        <SectionTitle>💻 Externí spolupráce na projektech</SectionTitle>
                        <Paragraph>
                            <b>Crowningarts.com</b> - sociální síť pro umělce <br/>
                            <b>Steller.co</b> - foto/video příběhy pro vášnivé cestovatele <br/>
                            <b>LaserGame-hk.cz</b> - rezervační systém <br/>
                            <b>Foosapp.eu</b> - aplikace pro stolní fotbálek<br/>
                            <b>Tanix.cz</b> - monitoring IoT zařízení
                        </Paragraph>

                        <SectionTitle>🧑‍🎓 Studium</SectionTitle>
                        <Paragraph>
                            <b>Fakulta informatiky a managmentu UHK</b> - 2022/stále student (Bc.) <br/>
                            obor aplikovaná informatika
                        </Paragraph>
                        <Paragraph>
                            <b>České vysoké učení technické v Praze</b> - 2021/2022 <br/>
                            studium webových technologií, programování a algoritmizace, databáze, ...
                        </Paragraph>
                        <Paragraph>
                            <b>DELTA SŠIE</b> - 2017/2021 ukončeno maturitou <br/>
                            Programování (C#) a kódování (HTML, CSS, PHP, JS),
                            Konfigurace PC, správa databází, Správa sítí,
                            Linux,
                            Databáze (MySQL), PL/SQL,
                            Produkty Adobe (PS, AI) a Microsoft Office
                        </Paragraph>

                        <SectionTitle>💼 Pracovní zkušenosti</SectionTitle>
                        <Paragraph>
                            <b>Nextap</b> - 2020/současnost <br/>
                            Frontend developer - Node.js, React, Angular (js), Astro, ... <br/>
                        </Paragraph>

                        <Paragraph>
                            <b>Paiwand (Afghan association London)</b> - 2019 <br/>
                            Stáž v rámci programu Erasmus, práce na interním systému
                        </Paragraph>

                        <Paragraph>
                            <b>DAMI development s.r.o.</b> - 2019/2020 <br/>
                            Junior webdeveloper, PHP (Yii2), JS (React.JS)
                        </Paragraph>

                        <Paragraph>
                            <b>Tchk, ZŠ BCUO, ...</b> - 2019/... <br/>
                            spolupráce na menších projektech
                        </Paragraph>

                        <SectionTitle>🏆 Soutěže</SectionTitle>
                        <Paragraph>
                            <b>Pardubický talent</b> - Pardubice - Červen 2022 <br/>
                            <b>Ocenění Pardubického kraje</b> - spolupráce na projektu burzaškol - Duben 2022 <br/>
                            <b>Ústřední kolo - programování web 2. místo</b> - MŠMT - Červenec 2021 <br/>
                            <b>Krajské kolo - programování web 1. místo</b> - MŠMT - Duben 2021 <br/>
                            <b>Hackathon KHK 2020 - 2. místo</b> - Královéhradecký kraj & tchk.cz - Říjen 2020 <br/>
                            <b>Mladý řemeslník roku</b> - SSŠČMS, se záštitou senátu ČR - Říjen 2019 <br/>
                            <b>Ústřední kolo - programování web 4. místo</b> - MŠMT - Červen 2019 <br/>
                            <b>Krajské kolo - programování web 2. místo</b> - MŠMT - Duben 2019 <br/>
                        </Paragraph>


                        <SectionTitle>🧳 Jazyky</SectionTitle>
                        <Paragraph>
                            Čeština, Angličtina
                        </Paragraph>
                    </Info>
                </Cols>
            </Center>
        </Layout>
    )
}

export default CV

const BgImage = styled.div`
  height: 15em;
  width: 15em;
  background-color: #e9e9e9;
  background-image: url("/img/profile.jpg");
  background-size: cover;
  border-radius: 10%;
  border: 1px solid #e9e9e9;

   @media (max-width: 739px) {
   display: none;
   }
`

const Cols = styled.div`
  @media (min-width: 740px) {
    display: grid;
    grid-template-columns: 15em auto;
  }
`

const Info = styled.div`
  @media (min-width: 740px) {
    padding-left: 4em;
  }
`

const Name = styled.h2`
  font-size: 2em;
  margin-bottom: 0;
`

const Sub = styled.p`
  margin-top: 0.5em;
`

export const SectionTitle = styled.h3`
  margin-top: 3em;
  font-size: 1.2em;
  margin-bottom: 0;
  position: relative;
`

export const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 2em;
  line-height: 1.8;
`
