import React from "react"
import styled from "styled-components"
import Layout from "../Layout";
import projects from "../../consts/projects";
import Reference from "../Welcome/Reference";
import {BigCenter, Center, PageTitle} from "../shared/mix";

interface Props {

}

const Portfolio = (props: Props) => {
    return (
        <Layout>
            <Center>
                <PageTitle>Reference</PageTitle>
            </Center>
            <BigCenter>
            {
                projects.map((project, index) => <Reference project={project} key={index}/>)
            }
            </BigCenter>
        </Layout>
    )
}

export default Portfolio
