import React from "react"
import styled from "styled-components";
import {Project, ProjectType} from "../../consts/projects";
import TabletMockup from "../Mockups/TabletMockup";
import PhoneMockup from "../Mockups/PhoneMockup";
import {Link} from "react-router-dom";

interface Props {
    project: Project
}

const WelcomeBanner = (props: Props) => {
    return (
        <>
            <WelcomeBannerWrapper tablet={props.project.type === ProjectType.TABLET}>
                <Info>
                    <MainTitle>
                        Systémy, web stránky a Aplikace
                    </MainTitle>
                    <Text>
                        Pojďmě najít řešení i pro Váš projekt.
                    </Text>
                    <Link to="/sluzby"><Btn>Služby</Btn></Link>
                    <Link to="/cv"><SecondaryBtn>CV</SecondaryBtn></Link>
                </Info>

                <Mockup>
                    {
                        props.project.type === ProjectType.TABLET ? (
                            <TabletMockup project={props.project}/>
                        ) : (
                            <PhoneMockup project={props.project}/>
                        )
                    }
                </Mockup>
            </WelcomeBannerWrapper>
        </>
    )
}

export default WelcomeBanner

interface WelcomeBannerWrapper {
    tablet?: boolean
}

const WelcomeBannerWrapper = styled.div<WelcomeBannerWrapper>`
  display: grid;
  grid-template-columns: ${({tablet}) => tablet ? "auto 45em" : "auto 30em"};
  align-items: center;
  margin-top: 7em;
  margin-bottom: 5em;
  
  @media (max-width: 1260px) {
    grid-template-columns: ${({tablet}) => tablet ? "auto 35em" : "auto 30em"};
  }
  
  @media (max-width: 1100px) {
    grid-template-columns: ${({tablet}) => tablet ? "auto" : "auto"};
  }
`

const Mockup = styled.div`
  @media (max-width: 1260px) {
    font-size: 0.7em;
  }
  
  @media (max-width: 1100px) {
    display: none;
  }
`


const Info = styled.div`
  @media (max-width: 1100px) {
    max-width: 40em;
    margin: 5em auto;
  }
`

const MainTitle = styled.h2`
  font-weight: 800;
  font-size: 3.6em;
  line-height: 1.3em;
  margin: 0;
`

const Text = styled.p`
  margin: 2em 0;
  max-width: 30em;
  opacity: 0.8;
`

export const Btn = styled.button`
    background-color: rgba(25,25,25, 1);
    color: white;
    border: none;
    padding: 0.7em 1em;
    font-size: 1em;
    outline: none;
    border-radius: 0.4em;
    cursor: pointer;
    transition: 0.15s;
    margin-right: 0.25em;

    &:hover {
      transform: scale(1.02);
    }
`

export const SecondaryBtn = styled(Btn)`
    background-color: #d9d9d9;
    color: black;
`
