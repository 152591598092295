import React from "react"
import styled from "styled-components"
import {Btn, SecondaryBtn} from "../Welcome/WelcomeBanner";
import {BigCenter} from "../shared/mix";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";

interface Props {

}

const CTA = (props: Props) => {
    const handleClick = () => {
        ReactGA.event({
            category: 'CTA-click',
            action: "info-more"
        });
    }

    return (
        <BigCenter>
            <Poster>
                <Text>Zaujal jsem Vás? Potřebujete něco ze světa webu, nebo počítačové grafiky?</Text>
                <div>
                    <Link to="/kontakt"><Btn onClick={handleClick}>Kontakt</Btn></Link>
                    <Link to="/reference"><SecondaryBtn onClick={handleClick}>Reference</SecondaryBtn></Link>
                </div>
            </Poster>
        </BigCenter>
    )
}

export default CTA

const Poster = styled.div`
  min-height: 20em;
  margin-top: 15em;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 2em;
  flex-direction: column;
  
  @media (min-width: 740px) {
      padding: 2em 8em;
  }
`

const Text = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 2em;
  line-height: 1.2em;
  
  @media (min-width: 740px) {
    font-size: 2.5em;
    margin-bottom: 0.5em;
 
  }
`
